// libs swiperを使う際はbabel管理外にしないといけないので注意
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

import Lottie from 'lottie-web';

// component
import Movie from '../common/ui/movie.es6';
import Modal from '../common/ui/modal.es6';

export default class Main {
    constructor() {
        this.introJsonPath = './assets/json/noa_logo_lottie.json';
        this.initModalJsonPath = './assets/json/initmodal.json';
        this.delay = 3000;
        this.movie = new Movie();
        this.modal = new Modal();
        this.init();
    }

    init() {
        this.swiper();
        this.intro();
        this.initModal();
    }

    intro() {
        const animationItem = Lottie.loadAnimation({
            container: document.getElementById('intro__logo'), // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: true,
            path: this.introJsonPath, // the path to the animation json
        });

        animationItem.addEventListener('complete', () => {
            setTimeout(() => {
                document.body.classList.add('-introend');
            }, 500);
        });
    }

    async initModal() {
        try {
            // セッションストレージに履歴がある場合は何もしない
            const modalOpened = sessionStorage.getItem('modalOpened');
            if (modalOpened) return;

            const data = await this.fetchModalData();
            this.processModalData(data);
        } catch (error) {
            console.error('Modalの初期化中にエラーが発生しました:', error);
        }
    }

    async fetchModalData() {
        const response = await fetch(this.initModalJsonPath);
        return await response.json();
    }

    async processModalData(data) {
        const startDate = new Date(data.startdate);
        const endDate = new Date(data.enddate);
        const now = new Date();

        if (now >= startDate && now <= endDate) {
            if (data.modal_type === 'movie') {
                await this.setupMovieModal(data.youtube_id);
            } else if (data.modal_type === 'imglink') {
                this.setupImageLinkModal(data.img_src, data.link_url);
            } else {
                this.setupImageModal(data.img_src);
            }

            // セッションストレージに履歴を追加
            sessionStorage.setItem('modalOpened', 'true');
        }
    }

    async setupMovieModal(youtubeId) {
        await this.waitForPlayerInitialization();

        setTimeout(() => {
            this.modal.autoOpen('movie');
            this.movie.loadVideo(youtubeId);
        }, this.delay);
    }

    setupImageLinkModal(imgSrc, linkUrl) {
        setTimeout(() => {
            this.modal.autoOpen('imglink', imgSrc, linkUrl);
        }, this.delay);
    }

    setupImageModal(imgSrc) {
        setTimeout(() => {
            this.modal.autoOpen('img', imgSrc);
        }, this.delay);
    }

    waitForPlayerInitialization() {
        return new Promise((resolve) => {
            const checkInterval = setInterval(() => {
                if (this.movie.player && this.movie.player.loadVideoById) {
                    clearInterval(checkInterval);
                    resolve();
                }
            }, 100);
        });
    }

    swiper() {
        // 画面幅に対して足りない分のスライドを複製して埋めるようにするには
        // Swiper8以下でないといけない
        const bnrSwiper = new Swiper('.bnrSwiper', {
            loop: true,
            slidesPerView: 'auto',
            spaceBetween: 10,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.bnrSwiper-pagination',
                clickable: true,
            },
        });

        const discographySwiper = new Swiper('.discographySwiper', {
            slidesPerView: 'auto',
            spaceBetween: 24,
            navigation: {
                nextEl: '.discographySwiper-next',
                prevEl: '.discographySwiper-prev',
            },
        });

        const movieSwiper = new Swiper('.movieSwiper', {
            slidesPerView: 'auto',
            spaceBetween: 20,
            navigation: {
                nextEl: '.movieSwiper-next',
                prevEl: '.movieSwiper-prev',
            },

            on: {
                afterInit: () => {
                    //　動的に追加された要素にイベントを再設定する
                    this.modal.resetEvent();
                    this.modal.eventBind();
                    this.movie.eventBind();
                },
            },
        });
    }
}
